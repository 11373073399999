.container.page-wrapper {
    display: table;
    height: 100%;
    margin: auto;
  }
  
  .page-inner {
    /* display: table-cell;
    vertical-align: middle; */
  }
  
  .el-wrapper {
    /* width: 360px; */
    /* padding: 5px; */
    /* margin: 15px auto; */
    background-color: #fff;
    transition: all 0.8s cubic-bezier(0, 0, 0.18, 1);
}
  
  .el-wrapper:hover .h-bg {
    left: 0px;
  }
  
  /* .el-wrapper:hover .price {
    left: 20px;
    transform: translateY(-50%);
    color: #818181;
  } */
  
  /* .el-wrapper:hover .add-to-cart {
    left: 50%;
  } */
  
  .el-wrapper:hover .img {
    opacity: 0.4;
    filter: invert(1) blur(14px);
    background-color: white;
}
  
.el-wrapper:hover .info-inner {
    bottom: 155px;
    background-color: #34288ead;
    padding: 10px 0px;
}
/* .el-wrapper:hover .info-inner:after {
    content: "";
    height: 49px;
    width: 51px;
    background: #30287478;
    position: absolute;
    left: -31px;
    transform: rotate(45deg);
    top: 16%;
  
} */
.el-wrapper:hover .info-inner .p-name{
    color:white
}
.el-wrapper:hover .info-inner .p-company{
    color:rgb(209, 203, 203)
}
  
  .el-wrapper:hover .a-size {
    transition-delay: 300ms;
    bottom: 50px;
    opacity: 1;
  }
  
  .box-down {
    width: 100%;
    height: 36px;
    position: relative;
    background: #3d2fa9;
    overflow: hidden;
}
  
  .box-up {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .img {
    padding: 20px 0;
    transition: all 0.8s cubic-bezier(0, 0, 0.18, 1);
  }
  
  .h-bg {
    transition: all 0.8s cubic-bezier(0, 0, 0.18, 1);
    width: 660px;
    height: 100%;
    background-color: #3f96cd;
    position: absolute;
    left: -659px;
  }
  
  .h-bg .h-bg-inner {
    width: 50%;
    height: 100%;
    background-color: #464646;
  }
  
  .info-inner {
    transition: all 0.4s cubic-bezier(0, 0, 0.18, 1);
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 25px;
    flex-direction: column;
}
  
  .p-name {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    color: #252525;
    text-transform: capitalize;
  }
  
  .p-company {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    text-transform: capitalize;
    color: #8c8c8c;
}
  
  .a-size {
    transition: all 0.3s cubic-bezier(0, 0, 0.18, 1);
    position: absolute;
    width: 100%;
    bottom: -20px;
    font-family: 'PT Sans', sans-serif;
    /* color: #828282; */
    opacity: 0;
  }
  
  .size {
    color: #252525;
  }
  
  .price {
    transition: all 0.6s cubic-bezier(0, 0, 0.18, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #fffbfb;
}
  
  .add-to-cart {
    transition: all 0.6s cubic-bezier(0, 0, 0.18, 1);
    position: absolute;
    top: 50%;
    left: 110%;
    transform: translate(-50%, -50%);
  }
  
  .add-to-cart .txt {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    white-space: nowrap;
  }
  