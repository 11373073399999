@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

body {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  z-index: 1000;
  overflow: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(203, 200, 200);
  border-radius: 12px;
}

@media only screen and (max-width:440px) {
  ::-webkit-scrollbar {
    width: 4px;
    z-index: 1000;
    overflow: hidden;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(203, 200, 200);
    border-radius: 12px;
  }
}


.joyride-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust transparency as needed */
  z-index: 10000;
}

.joyride-step {
  position: relative;
  z-index: 10001;
}

.joyride-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* Ensure no overflow */
  margin: 0;
  padding: 0;
}



/* Add this to your styles.css */
.joyride-tooltip {
  max-width: 400px;
  /* Adjust this value as needed */
  width: auto;
  padding: 1rem;
}

.joyride-tooltip__content {
  max-height: 400px;
  /* Adjust this value as needed */
  overflow-y: auto;
}

.joyride-tooltip__content ol {
  padding-left: 20px;
  margin: 0;
}

.joyride-tooltip__content ol li {
  margin-bottom: 10px;
}






