.no-display {
  display: none;
  /* Initially hide the element */
}

/* Show element when printing */
@media print {
  .no-display {
    display: block;
    /* Show in print */
  }
}

#print-content {
  position: absolute;
  /* Keep it out of the visible area */
  left: -9999px;
  /* Move it far off-screen */
  top: 0;
  /* Normal top position */
}

@media print {
  #print-content {
    position: static;
    /* Reset position to normal for printing */
    left: auto;
    /* Reset left to auto */
    top: auto;
    /* Reset top to auto */
    display: block;
    /* Ensure it is displayed during print */
  }
}