.file-upload-form {
    width: fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-upload-label input {
    display: none;
  }
  .file-upload-label svg {
    height: 50px;
    fill: rgb(82, 82, 82);
    margin-bottom: 20px;
  }
  .file-upload-label {
    cursor: pointer;
    background-color: #f0f0f0;
    margin-top: 17px;
    padding: 8px 37px;
    border-radius: 40px;
    border: 2px dashed #1565c0;
    box-shadow: 0px 0px 200px -50px rgba(0, 0, 0, 0.719);
}
  .file-upload-design {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .browse-button {
    background-color: rgb(82, 82, 82);
    padding: 5px 15px;
    border-radius: 10px;
    color: white;
    transition: all 0.3s;
  }
  .browse-button:hover {
    background-color: rgb(14, 14, 14);
  }
  